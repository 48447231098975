import React, { useEffect, useState } from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import qs from "qs"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { getDonationPayment } from "../services/api"
import { useSiteMeta } from "../hooks/siteMeta"

const Container = styled.div`
  max-width: 760px;
  margin: 0 auto;
  padding: 6.81em 15px;

  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.81em;
  align-items: center;

  @media all and (min-width: 768px) {
    grid-template-columns: 30% 70%;
  }
`

const SUCCESS = "Succeeded"

const ThanksPage = ({ location: { search }, data }) => {
  const [status, setStatus] = useState(false)
  const { email } = useSiteMeta()

  useEffect(() => {
    loadDonationStatus()
  })

  const loadDonationStatus = async () => {
    try {
      const { paymentId } = qs.parse(search, { ignoreQueryPrefix: true })
      const result = await getDonationPayment(paymentId)
      setStatus(result.data.status === SUCCESS)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Layout>
      <SEO title="Köszönjük az adományozást!" />
      <Container>
        <Img fluid={data.heroImage.src.fluid} />
        {status ? (
          <h1>Köszönjük, hogy adományoddal támogattad a nagybetűs Hősöket!</h1>
        ) : (
          <h1>
            Sajnos hiba történt a fizetés közben! Kérlek vedd fel a kapcsolatott
            velünk.
          </h1>
        )}
        <Link to="/">Vissza a főoldalra</Link>
        {!status && <a href={`mailto:${email}`}>Kapcsolat felvétel</a>}
      </Container>
    </Layout>
  )
}

export default ThanksPage

export const pageQuery = graphql`
  query {
    heroImage: file(relativePath: { eq: "Etesdahosoket.png" }) {
      src: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
